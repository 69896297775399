
export function GetButtonFunc(MenuId,GroupIndex) {
    let funlist=JSON.parse(localStorage.getItem('buttonFuncCode'))
    return funlist.filter(s=>s.menuId==MenuId&&s.groupIndex==GroupIndex)
}

const state = { 
  buttonFuncCode: []
}

const mutations = {
  Set_Button_FuncCode: (state,  funCodeArray) => {
    localStorage.setItem('buttonFuncCode',JSON.stringify(funCodeArray));
  }
}

const actions = {
  SetButton_FuncCode({ commit },funCodeArray) {
    commit('Set_Button_FuncCode', funCodeArray)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
