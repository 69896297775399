// 公共样式
export const commonStyle = {
    rotate: 0,
    opacity: 1,
}

export const commonAttr = {
    animations: [],
    events: {},
    groupStyle: {}, // 当一个组件成为 Group 的子组件时使用
    isLock: false, // 是否锁定组件
    collapseName: 'style', // 编辑组件时记录当前使用的是哪个折叠面板，再次回来时恢复上次打开的折叠面板，优化用户体验
    linkage: {
        duration: 0, // 过渡持续时间
        data: [ // 组件联动
            {
                id: '', // 联动的组件 id
                label: '', // 联动的组件名称
                event: '', // 监听事件
                style: [{ key: '', value: '' }], // 监听的事件触发时，需要改变的属性
            },
        ],
    },
}


export const binditem={

    stationId:0,
    stationCode:'',
    stationName:'',
    deviceId:0,
    deviceCode:'',
    deviceName:'',
    collectItemId:0,
    typeCode:'',
    itemName:'',
    info:'',//组合信息显示
   
};
export const repeat={

    series: true, // 是否定时发送请求
    time: 1000, // 定时更新时间
    paramType: '', // string object array
    requestCount: 0, // 请求次数限制，0 为无限   
};

 

// 编辑器左侧组件列表
const list = [
    {
        component: 'VText',
        componentclassid:1,
        label: '文字',
        propValue: '双击编辑文字',
        icon: 'wenben',
        request: {         
            binditem:{...binditem},
            repeat: {...repeat},

        },
        style: {
            width: 200,
            height: 28,
            fontSize: '',
            fontWeight: 400,
            lineHeight: '',
            letterSpacing: 0,
            textAlign: '',
            color: '',
        },
    },
    {
        component: 'VButton',
        componentclassid:1,
        label: '按钮',
        propValue: '按钮',
        icon: 'button',
        style: {
            width: 100,
            height: 34,
            borderWidth: 1,
            borderColor: '',
            borderRadius: '',
            fontSize: '',
            fontWeight: 400,
            lineHeight: '',
            letterSpacing: 0,
            textAlign: '',
            color: '',
            backgroundColor: '',
        },
    },
    {
        component: 'Picture',
        componentclassid:1,
        label: '图片',
        icon: 'tupian',
        propValue: {
            url: require('@/assets/title.jpg'),
            flip: {
                horizontal: false,
                vertical: false,
            },
        },
        style: {
            width: 200,
            height: 200,
            borderRadius: '',
        },
    },
    {
        component: 'RectShape',
        componentclassid:1,
        label: '矩形',
        propValue: '&nbsp;',
        icon: 'juxing',
        style: {
            width: 200,
            height: 200,
            fontSize: '',
            fontWeight: 400,
            lineHeight: '',
            letterSpacing: 0,
            textAlign: 'center',
            color: '',
            borderColor: '#000',
            borderWidth: 1,
            backgroundColor: '',
            borderStyle: 'solid',
            borderRadius: '',
            verticalAlign: 'middle',
        },
    },
    {
        component: 'LineShape',
        componentclassid:1,
        label: '直线',
        propValue: '',
        icon: 'zhixian',
        style: {
            width: 200,
            height: 2,
            backgroundColor: '#000',
        },
    },
    {
        component: 'CircleShape',
        componentclassid:1,
        label: '圆形',
        propValue: '&nbsp;',
        icon: '24gl-circle',
        style: {
            width: 200,
            height: 200,
            fontSize: '',
            fontWeight: 400,
            lineHeight: '',
            letterSpacing: 0,
            textAlign: 'center',
            color: '',
            borderColor: '#000',
            borderWidth: 1,
            backgroundColor: '',
            borderStyle: 'solid',
            borderRadius: '',
            verticalAlign: 'middle',
        },
    },
    {
        component: 'SVGStar',
        componentclassid:1,
        label: '星形',
        icon: 'kongwujiaoxing',
        propValue: '',
        style: {
            width: 80,
            height: 80,
            fontSize: '',
            fontWeight: 400,
            lineHeight: '',
            letterSpacing: 0,
            textAlign: 'center',
            color: '',
            borderColor: '#000',
            backgroundColor: 'rgba(255, 255, 255, 1)',
        },
    },
    {
        component: 'SVGTriangle',
        componentclassid:1,
        label: '三角形',
        icon: 'xingzhuang-sanjiaoxing',
        propValue: '',
        style: {
            width: 80,
            height: 80,
            fontSize: '',
            fontWeight: 400,
            lineHeight: '',
            letterSpacing: 0,
            textAlign: 'center',
            color: '',
            borderColor: '#000',
            backgroundColor: 'rgba(255, 255, 255, 1)',
        },
    },
    {
        component: 'VTable',
        componentclassid:1,
        label: '表格',
        icon: 'biaoge',
        propValue: {
            data: [
                ['表头1', '表头2', '表头3'],
                ['内容1', '内容2', '内容3'],
            ],
            stripe: true,
            thBold: true,
        },
        request: {
            method: 'GET',
            data: [],
            url: '',
            series: false,
            time: 1000,
            paramType: '', // string object array
            requestCount: 0, // 请求次数限制，0 为无限
        },
        style: {
            width: 600,
            height: 200,
            fontSize: '',
            fontWeight: 400,
            textAlign: 'center',
            color: '',
            backgroundColor: 'rgba(255, 255, 255, 1)',
        },
    },
    {
        component: 'KonvaCircle',
        componentclassid:1,
        label: '流动条',
        propValue: {
            configKonva:
            {
                width: 200,
                height: 200
            },
            configCircle:
            {
                x: 100,
                y: 100,
                radius: 100,
                fill: "red",
                stroke: "black",
                strokeWidth: 4
            },
            configText:  {
                text: 'Draggable Text',
                x: 50,
                y: 50,
                draggable: true,
               // fill: isDragging ? 'green' : 'black'
               fill:  'green' 
              },
              ExternalChannel:{ //外部管道
                stroke:'#0000ff',//管道颜色
                strokeWidth:10, //管道宽度 
                path:'M 20,100 H 180'
              },
              InnerChannel:{ //内部管道
                stroke:'#e69021',//管道颜色
                strokeWidth:2, //管道宽度 
                dashWidth:10,//
                dashInterval:10,//虚线间隔
                direction:true,//流动方向
                speed:0.3,//流动速度
                enableAnimation:false,//是否启用动画
                styleAnimation:0,//动画样式
              },

              anchor:[],//圆形装饰器配置，用来改变PATH长度和形状
             


        },
        icon: 'zhixian', 
        style: {
            width: 200,
            height: 200,
            backgroundColor: 'rgba(255, 255, 255, 0)',
        },
    },
    {
        component: 'LoadGifTest',
        componentclassid:1,
        label: '加载Gif',
        propValue: {
            configKonva:
            {
                width: 200,
                height: 200
            },

            // url:require('@/assets/svgmodel/变压器.svg'),
           url:require('@/assets/fans/8.gif'),

        },
        icon: 'GIF',
        style: {
            width: 200,
            height: 200,
            backgroundColor: 'rgba(255, 255, 255, 0)',
        },
    },
    {
        component: 'LoadSvgTest',
        componentclassid:1,
        label: '加载SVG',
        propValue: {
            configKonva:
            {
                width: 200,
                height: 200
            },

           // url:'/svgmodel/变压器.svg',
           url:'/svgmodel/2.svg',

        },
        icon: 'SVG',
        style: {
            width: 200,
            height: 200,
            backgroundColor: 'rgba(255, 255, 255, 1)',
        },
    },
    {
        component: 'LoadSvgPath',
        componentclassid:1,
        label: '隔离开关',
        request: {         
            binditem:{...binditem},
            repeat: {...repeat},

        },
        propValue: {
            configKonva:
            {
                width: 200,
                height: 200,
            },
            
          path:[
         
            'M 1.8524425,25 H 19.239476',           
            'm 31.779751,22.011963 v 5.904134',
            'M 31.827112,25 H 46.905697',
          ],
            stroke: '#000000',//颜色
            strokeWidth: 1, //宽度 
            scaleX: 1,//X缩放
            scaleY: 1,//Y缩放
            defaultWith: 50,//默认组件宽度
            defaultHeight: 50,//默认组件高度
            specialPath: {
                stroke: '#ff0000',//颜色ff7f2a
                strokeWidth: 1, //宽度

            },
            specialPath0: { //特殊路径
                path: [

                    'M 30.480938,18.18634 18.938699,25.062568',
                ],

            },
            specialPath1: { //特殊路径
                path: [

                    'M 31.827112,25 19.04289,25.010473',
                ],
            },  


          onoff:0, //开关初始状态
        },
        icon: 'switch',
        style: {
            width: 50,
            height: 50,
            backgroundColor: 'rgba(255, 255, 255, 0)',
        },
    }, 
    
    
]
 
//  list.concat(componentList)
//  console.log(componentList.length)
//  console.log(list.length)
//自动加载分类配置 目录分类
var classCount=4;
for (let i = 2; i < classCount; i++) {

     
   var lst= require(`@/custom-component/componentclassid${i}/component-list`).default 
   if(lst.length==0) continue;
   lst.forEach(c=>{
            list.push(c);
         });
 
} 
//组件目录分类
export const componentclassCount = {
    beginId:2,
    endId:classCount
}


for (let i = 0, len = list.length; i < len; i++) {
    const item = list[i]
    item.style = { ...commonStyle, ...item.style }
    list[i] = { ...commonAttr, ...item,index:i }
}

export default list
