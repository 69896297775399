import { Message, MessageBox } from 'element-ui'

export default {

  success: function (message = "成功", type = "success", duration = 1500, offset = 60) {
    Message({
      message,
      type,
      duration,
      offset,
    });
  },
  error: function (message = "错误", type = "error", duration = 1500, offset = 60) {
    Message({
      message,
      type,
      duration,
      offset,
    });
  },

  confirm: function ({ message = "", title = "提示", type = "warning", confirm, cancel }) {

    MessageBox.confirm(message, title, {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: type
    }).then(() => {

      confirm && confirm();

    }).catch(() => {

      cancel && cancel();
    });

  },
};
