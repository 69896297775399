import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/index'
import { actions } from '../store/account'

import layout from '@/views/Main/Index.vue'

Vue.use(Router)

const routes = [
    // { 
    //     path: '/',
    //     name: 'Main',
    //     meta: {
    //         requireAuth: true, //配置是否需要登陆验证
    //     },
    //     component: () =>layout, 
    // },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/LoginV2'),
        hidden: true,
    },


    // 根据各大类功能配置路由
    {

        path: '/BasicInformation',
        component: layout,
        meta: {
            requireAuth: true, // 配置是否需要登陆验证    title: '基础信息',  
        },
        children: [
            {
                path: 'Energytype',
                name: 'BasicInformation_Energytype',
                component: () => import('@/views/BasicInformation/Energytype'),
                meta: { title: '通用能源信息', icon: 'dashboard', menuId: 1001 },
            },
            {
                path: 'EsStdUnit',
                name: 'BasicInformation_EsStdUnit',
                component: () => import('@/views/BasicInformation/EsStdUnit'),
                meta: { title: '通用能源单位', icon: 'dashboard' },
            },
            {
                path: 'EsBasePeakPingGu',
                name: 'BasicInformation_EsBasePeakPingGu',
                component: () => import('@/views/BasicInformation/EsBasePeakPingGu'),
                meta: { title: '用电峰平谷时段定义', icon: 'dashboard' },
            },

        ],
    },
    {
        path: '/StationInfo',
        component: layout,
        meta: {
            requireAuth: true, // 配置是否需要登陆验证  title: '站点信息',  
        },
        children: [{
            path: 'Enterprise',
            name: 'StationInfo_Enterprise',
            component: () => import('@/views/StationInfo/Enterprise/Index'),
            meta: { title: '站点信息', icon: 'dashboard', menuId: 38 },
        },
        {
            path: 'EnterpriseExt',
            name: 'StationInfo_EnterpriseExt',
            component: () => import('@/views/StationInfo/EnterpriseExt/Index'),
            meta: { title: '站点扩展信息', icon: 'dashboard', menuId: 39 },
        },
        {
            path: 'EnergyType',
            name: 'StationInfo_EnergyType',
            component: () => import('@/views/StationInfo/EnergyType/Index'),
            meta: { title: '站点能源信息', icon: 'dashboard', menuId: 43 },
        },
        {
            path: 'Products',
            name: 'StationInfo_Products',
            component: () => import('@/views/StationInfo/Products/Products'),
            meta: { title: '企业产品信息', icon: 'dashboard' },
        },
        {
            path: 'Shift',
            name: 'StationInfo_Shift',
            component: () => import('@/views/StationInfo/Shift/Index'),
            meta: { title: '站点班次班组', icon: 'dashboard', menuId: 44 },
        },
        {
            path: 'Layer',
            name: 'StationInfo_Layer',
            component: () => import('@/views/StationInfo/Layer/Index'),
            meta: { title: '站点层级', icon: 'dashboard', menuId: 45 },
        },
        ],
    },

    {

        path: '/OperationManagement', //运维管理
        component: layout,
        redirect: 'noRedirect',
        meta: {
            requireAuth: true, // 配置是否需要登陆验证    title: '基础信息',  
            title: '数据维护',
        },
        children: [
            {
                path: 'CollectItemDataHistory',
                name: 'OperationManagement_CollectItemDataHistory',
                component: () => import('@/views/OperationManagement/CollectItemDataHistory'),
                meta: { title: '采集数据修改', icon: 'dashboard' },
            },
            {
                path: 'ManualFilling',
                name: 'OperationManagement_ManualFilling',
                component: () => import('@/views/OperationManagement/ManualFilling'),
                meta: { title: '人工录入数据', icon: 'dashboard' },
            },
            {
                path: 'EnergyCoefficient',
                name: 'OperationManagement_EnergyCoefficient',
                component: () => import('@/views/OperationManagement/EnergyCoefficient'),
                meta: { title: '能源系数填报', icon: 'dashboard' },
            },
            {
                path: 'CollectitemDataDayShiftClassProductQuantity',
                name: 'OperationManagement_CollectitemDataDayShiftClassProductQuantity',
                component: () => import('@/views/OperationManagement/CollectitemDataDayShiftClassProductQuantity'),
                meta: { title: '产品实际生产信息', icon: 'dashboard' },
            },

        ],
    },


    {
        path: '/',
        component: layout,
        redirect: '/dashboard',
        meta: {
            requireAuth: true, // 配置是否需要登陆验证
        },
        children: [{
            path: 'dashboard',
            name: 'Dashboard',
            component: () => import('@/views/Main/Dashboard.vue'),
            meta: { title: '首页', icon: 'dashboard', affix: true },
        }],
    },
    {
        path: '/BaseManagement',
        component: layout,
        meta: {
            requireAuth: true, // 配置是否需要登陆验证
        },
        children: [
            {
                path: 'CollectItemType',
                name: 'BaseManagement_CollectItemType',
                component: () => import('@/views/CollectItemType/Index'),
                meta: { title: '参数类型管理', icon: 'dashboard', menuId: 70 },
            },
            {
                path: 'AlarmTemplate',
                name: 'BaseManagement_AlarmTemplate',
                component: () => import('@/views/AlarmTemplate/Index'),
                meta: { title: '告警模版管理', icon: 'dashboard', menuId: 71 },
            },
            {
                path: 'PowerDeviceType',
                name: 'BaseManagement_PowerDeviceType',
                component: () => import('@/views/PowerDeviceType/Index'),
                meta: { title: '设备类型管理', icon: 'dashboard', menuId: 69 },
            },
            {
                path: 'PowerDevice',
                name: 'BaseManagement_PowerDevice',
                component: () => import('@/views/PowerDevice/Index'),
                meta: { title: '设备管理', icon: 'dashboard', menuId: 28 },
            },
        ],
    },
    {
        path: '/ClassificationSubitemFormula',
        component: layout,
        meta: {
            requireAuth: true, // 配置是否需要登陆验证
        },
        children: [
            {
                path: 'Index1',
                name: 'ClassificationSubitemFormula_Index1',
                component: () => import('@/views/ClassificationSubitemFormula/Index1'),
                meta: { title: '耗能公式维护', icon: 'dashboard' },
            },
            {
                path: 'Index3',
                name: 'ClassificationSubitemFormula_Index3',
                component: () => import('@/views/ClassificationSubitemFormula/Index3'),
                meta: { title: '线损公式维护', icon: 'dashboard' },
            },
            {
                path: 'Index4',
                name: 'ClassificationSubitemFormula_Index4',
                component: () => import('@/views/ClassificationSubitemFormula/Index4'),
                meta: { title: '自定义报表公式维护', icon: 'dashboard' },
            },
            {
                path: 'Index5',
                name: 'ClassificationSubitemFormula_Index5',
                component: () => import('@/views/ClassificationSubitemFormula/Index5'),
                meta: { title: '能源平衡表公式维护', icon: 'dashboard' },
            },
        ],
    },
    {
        path: '/MeasuringInstrument',
        component: layout,
        meta: {
            requireAuth: true, // 配置是否需要登陆验证
        },
        children: [{
            path: 'Index',
            name: 'MeasuringInstrument_Index',
            component: () => import('@/views/MeasuringInstrument/Index'),
            meta: { title: '计量器具台账', icon: 'dashboard' },
        },
        {
            path: 'Verification',
            name: 'MeasuringInstrument_Verification',
            component: () => import('@/views/MeasuringInstrument/Verification'),
            meta: { title: '计量器具检定', icon: 'dashboard' },
        },
        {
            path: 'Query',
            name: 'MeasuringInstrument_Query',
            component: () => import('@/views/MeasuringInstrument/Query'),
            meta: { title: '计量器具综合查询', icon: 'dashboard' },
        },
        {
            path: 'Manufacturer',
            name: 'MeasuringInstrument_Manufacturer',
            component: () => import('@/views/MeasuringInstrument/Manufacturer'),
            meta: { title: '计量器具生产厂家', icon: 'dashboard' },
        },
        ],
    },
    {
        path: '/SystemManagement',
        component: layout,
        meta: {
            requireAuth: true, // 配置是否需要登陆验证
        },
        children: [{
            path: 'AccountInfo',
            name: 'SystemManagement_AccountInfo',
            component: () => import('@/views/AccountInfo/Index'),
            meta: { title: '用户管理', icon: 'dashboard' },
        },
        {
            path: 'RoleInfo',
            name: 'SystemManagement_RoleInfo',
            component: () => import('@/views/RoleInfo/Index'),
            meta: { title: '角色管理', icon: 'dashboard' },
        },
        {
            path: 'MenuInfo',
            name: 'SystemManagement_MenuInfo',
            component: () => import('@/views/MenuInfo/Index'),
            meta: { title: '菜单管理', icon: 'dashboard' },
        },
        {
            path: 'OperateLog',
            name: 'SystemManagement_OperateLog',
            component: () => import('@/views/OperateLog/Index'),
            meta: { title: '系统日志', icon: 'dashboard' },
        },
        ],
    },
    {
        path: '/EnergyBalance',
        component: layout,
        meta: {
            requireAuth: true, // 配置是否需要登陆验证
        },
        children: [{
            path: 'EnergyBalanceYearInventory',
            name: 'EnergyBalance_YearInventory',
            component: () => import('@/views/EnergyBalance/EnergyBalanceYearInventory'),
            meta: { title: '能源平衡表年初库存', icon: 'dashboard' },
        },
        ],
    },
    {
        path: '/Report',
        component: layout,
        meta: {
            requireAuth: true, // 配置是否需要登陆验证
        },
        children: [{
            path: 'IndexProductRelatedEnergyReport/GetProductCost',
            name: 'IndexProductRelatedEnergyReport_GetProductCost',
            component: () => import('@/views/Report/IndexProductRelatedEnergyReport/GetProductCost'),
            meta: { title: '产品能源消耗成本明细', icon: 'dashboard' },
        },
        {
            path: 'IndexProductRelatedEnergyReport/ProductConsumption',
            name: 'IndexProductRelatedEnergyReport_ProductConsumption',
            component: () => import('@/views/Report/IndexProductRelatedEnergyReport/ProductConsumption'),
            meta: { title: '产品能源综合成本报表', icon: 'dashboard' },
        },
        {
            path: 'IndexProductRelatedEnergyReport/ProductEnergyConsumptionCostQuery',
            name: 'ProductEnergyConsumptionCostQuery',
            component: () => import('@/views/Report/IndexProductRelatedEnergyReport/ProductEnergyConsumptionCostQuery'),
            meta: { title: '产品能源综合成本报表', icon: 'dashboard' },
        },
        {
            path: 'IndexProductRelatedEnergyReport/ProcessProductUnitConsumptionReport',
            name: 'IndexProductRelatedEnergyReport_ProcessProductUnitConsumptionReport',
            component: () => import('@/views/Report/IndexProductRelatedEnergyReport/ProcessProductUnitConsumptionReport'),
            meta: { title: '工序产品能耗分析', icon: 'dashboard' },
        },
        {
            path: 'IndexProductRelatedEnergyReport/UnitConversionEnergyconsumptionQuery',
            name: 'IndexProductRelatedEnergyReport_UnitConversionEnergyconsumptionQuery',
            component: () => import('@/views/Report/IndexProductRelatedEnergyReport/UnitConversionEnergyconsumptionQuery'),
            meta: { title: '工序单位产品能耗查询', icon: 'dashboard' },

        },
        {
            path: '/Report/IndexProductRelatedEnergyReport/UnitProductEnergyConsumptionCompareQuery',
            name: 'IndexProductRelatedEnergyReport_UnitProductEnergyConsumptionCompareQuery',
            component: () => import('@/views/Report/IndexProductRelatedEnergyReport/UnitProductEnergyConsumptionCompareQuery'),
            meta: { title: '工序单位产品能耗环比', icon: 'dashboard' },

        },
        {
            path: 'IndexPowerEnergyReport/PeakValleyPowerConsumptionQuery',
            name: 'IndexPowerEnergyReport_PeakValleyPowerConsumptionQuery',
            component: () => import('@/views/Report/IndexPowerEnergyReport/PeakValleyPowerConsumptionQuery'),
            meta: { title: '峰平谷电费分析', icon: 'dashboard' }, //按工序查询
        },
        {
            path: 'PowerDeviceElecDataReport',
            name: 'Report_PowerDeviceElecDataReport',
            component: () => import('@/views/Report/PowerDeviceElecDataReport'),
            meta: { title: '电力设备用电报表', icon: 'dashboard' },
        },
        {
            path: 'PowerDeviceSteamDataReport',
            name: 'Report_PowerDeviceSteamDataReport',
            component: () => import('@/views/Report/PowerDeviceSteamDataReport'),
            meta: { title: '蒸汽天然气用能报表', icon: 'dashboard' },
        },
        {
            path: 'EnergyEfficiencyBenchmarking',
            name: 'Report_EnergyEfficiencyBenchmarking',
            component: () => import('@/views/Report/EnergyEfficiencyBenchmarking'),
            meta: { title: '企业能效对标', icon: 'dashboard' },
        },
        {
            path: 'SingleEfficiencyBenchmarking',
            name: 'Report_SingleEfficiencyBenchmarking',
            component: () => import('@/views/Report/SingleEfficiencyBenchmarking'),
            meta: { title: '单耗数据对标', icon: 'dashboard' },
        },
        {
            path: 'IndexProductRelatedEnergyReport/UnitConversionEnergyconsumption',
            name: 'IndexProductRelatedEnergyReport_UnitConversionEnergyconsumption',
            component: () => import('@/views/Report/IndexProductRelatedEnergyReport/UnitConversionEnergyconsumption'),
            meta: { title: '单位产品能耗报表', icon: 'dashboard' },
        },
        {
            path: 'IndexPowerEnergyReport/PeakValleyPowerConsumptionReport',
            name: 'IndexPowerEnergyReport_PeakValleyPowerConsumptionReport',
            component: () => import('@/views/Report/IndexPowerEnergyReport/PeakValleyPowerConsumptionReport'),
            meta: { title: '峰平谷电耗报表', icon: 'dashboard' }, //按工序查询
        },
        {
            path: 'IndexPowerEnergyReport/FullFactoryPeakValleyPowerConsumptionReport',
            name: 'IndexPowerEnergyReport_FullFactoryPeakValleyPowerConsumptionReport',
            component: () => import('@/views/Report/IndexPowerEnergyReport/FullFactoryPeakValleyPowerConsumptionReport'),
            meta: { title: '企业峰平谷电耗报表', icon: 'dashboard' }, //按全厂查询
        },

        {
            path: 'IndexFullFactoryEnergyReport/SummaryEnterpriseEnergyConsumption',
            name: 'IndexFullFactoryEnergyReport_SummaryEnterpriseEnergyConsumption',
            component: () => import('@/views/Report/IndexFullFactoryEnergyReport/SummaryEnterpriseEnergyConsumption'),
            meta: { title: '企业能耗汇总表', icon: 'dashboard' },
        },
        {
            path: 'IndexFullFactoryEnergyReport/ProcessSummaryEnterpriseEnergyConsumption',
            name: 'IndexFullFactoryEnergyReport_ProcessSummaryEnterpriseEnergyConsumption',
            component: () => import('@/views/Report/IndexFullFactoryEnergyReport/ProcessSummaryEnterpriseEnergyConsumption'),
            meta: { title: '工序能耗汇总', icon: 'dashboard' },
        },
        {
            path: 'IndexFullFactoryEnergyReport/ProcessSummaryEnergyConsumptionCost',
            name: 'IndexFullFactoryEnergyReport_ProcessSummaryEnergyConsumptionCost',
            component: () => import('@/views/Report/IndexFullFactoryEnergyReport/ProcessSummaryEnergyConsumptionCost'),
            meta: { title: '工序能耗成本', icon: 'dashboard' },
        },
        {
            path: 'IndexFullFactoryEnergyReport/ProcessEnergyConversionEnergyConsumption',
            name: 'IndexFullFactoryEnergyReport_ProcessEnergyConversionEnergyConsumption',
            component: () => import('@/views/Report/IndexFullFactoryEnergyReport/ProcessEnergyConversionEnergyConsumption'),
            meta: { title: '工序能耗折标', icon: 'dashboard' },
        },
        {
            path: 'IndexFullFactoryEnergyReport/SummaryEnterpriseEnergyConsumptionCost',
            name: 'IndexFullFactoryEnergyReport_SummaryEnterpriseEnergyConsumptionCost',
            component: () => import('@/views/Report/IndexFullFactoryEnergyReport/SummaryEnterpriseEnergyConsumptionCost'),
            meta: { title: '企业能耗成本汇总表', icon: 'dashboard' },
        },
        {
            path: 'IndexFullFactoryEnergyReport/SummaryEnterpriseConversionEnergyConsumption',
            name: 'IndexFullFactoryEnergyReport_SummaryEnterpriseConversionEnergyConsumption',
            component: () => import('@/views/Report/IndexFullFactoryEnergyReport/SummaryEnterpriseConversionEnergyConsumption'),
            meta: { title: '企业能耗折标汇总表', icon: 'dashboard' },
        },
        {
            path: 'IndexFullFactoryEnergyReport/EnterpriseEnergyConsumptionCompare',
            name: 'IndexFullFactoryEnergyReport_EnterpriseEnergyConsumptionCompare',
            component: () => import('@/views/Report/IndexFullFactoryEnergyReport/EnterpriseEnergyConsumptionCompare'),
            meta: { title: '企业能耗比较报表', icon: 'dashboard' },
        },
        {
            path: 'IndexFullFactoryEnergyReport/ProcessEnterpriseEnergyConsumptionCompare',
            name: 'IndexFullFactoryEnergyReport_ProcessEnterpriseEnergyConsumptionCompare',
            component: () => import('@/views/Report/IndexFullFactoryEnergyReport/ProcessEnterpriseEnergyConsumptionCompare'),
            meta: { title: '工序能耗同比分析', icon: 'dashboard' },
        },

        {
            path: 'IndexFullFactoryEnergyReport/ProcessEnterpriseEnergyConsumptionHuanbi',
            name: 'IndexFullFactoryEnergyReport_ProcessEnterpriseEnergyConsumptionHuanbi',
            component: () => import('@/views/Report/IndexFullFactoryEnergyReport/ProcessEnterpriseEnergyConsumptionHuanbi'),
            meta: { title: '工序能耗比较', icon: 'dashboard' },
        },
        {
            path: 'IndexFullFactoryEnergyReport/EnterpriseEnergyConsumptionQuery',
            name: 'IndexFullFactoryEnergyReport_EnterpriseEnergyConsumptionQuery',
            component: () => import('@/views/Report/IndexFullFactoryEnergyReport/EnterpriseEnergyConsumptionQuery'),
            meta: { title: '企业能源消耗占比查询', icon: 'dashboard' },
        },
        {
            path: 'IndexFullFactoryEnergyReport/ProcessEnergyConsumptionQuery',
            name: 'IndexFullFactoryEnergyReport_ProcessEnergyConsumptionQuery',
            component: () => import('@/views/Report/IndexFullFactoryEnergyReport/ProcessEnergyConsumptionQuery'),
            meta: { title: '工序能源消耗占比查询', icon: 'dashboard' },
        },

        {
            path: 'IndexShiftClassProductRelatedEnergyReport/ShiftEnergyConsumptionReport',
            name: 'IndexShiftClassProductRelatedEnergyReport_ShiftEnergyConsumptionReport',
            component: () => import('@/views/Report/IndexShiftClassProductRelatedEnergyReport/ShiftEnergyConsumptionReport'),
            meta: { title: '班次能耗报表查询', icon: 'dashboard' },
        },
        {
            path: 'IndexShiftClassProductRelatedEnergyReport/ShiftProductEnergyConsumptionReport',
            name: 'IndexShiftClassProductRelatedEnergyReport_ShiftProductEnergyConsumptionReport',
            component: () => import('@/views/Report/IndexShiftClassProductRelatedEnergyReport/ShiftProductEnergyConsumptionReport'),
            meta: { title: '班次产品能耗报表查询', icon: 'dashboard' },
        },



        ],
    },
    {
        path: '/AlarmLog',
        component: layout,
        meta: {
            requireAuth: true, // 配置是否需要登陆验证
        },
        children: [{
            path: 'Search',
            name: 'AlarmLog_Search',
            component: () => import('@/views/AlarmLog/Search'),
            meta: { title: '告警查询', icon: 'dashboard' },
        },

        ],
    },
    {
        path: '/Query',
        component: layout,
        meta: {
            requireAuth: true, // 配置是否需要登陆验证
        },
        children: [{
            path: 'IndexLineLossQuery/LineLoss',
            name: 'IndexLineLossQuery_LineLoss',
            component: () => import('@/views/Query/IndexLineLossQuery/LineLoss'),
            meta: { title: '线路损耗查询', icon: 'dashboard' },
        },
        {
            path: 'IndexProcessEnergyQuery/EnergyConsumptionHorizontal',
            name: 'IndexProcessEnergyQuery_EnergyConsumptionHorizontal',
            component: () => import('@/views/Query/IndexProcessEnergyQuery/EnergyConsumptionHorizontal'),
            meta: { title: '能耗横向对比', icon: 'dashboard' },
        },
        ],
    },
    {
        path: '/Screen',
        component: layout,
        meta: {
            requireAuth: true, // 配置是否需要登陆验证
        },
        children: [
            {
                path: 'Index1',
                name: 'Screen_Index1',
                component: () => import('@/views/Screen/Index1'),
                meta: { title: '配电图管理', icon: 'dashboard' },
            },
            {
                path: 'Index2',
                name: 'Screen_Index2',
                component: () => import('@/views/Screen/Index2'),
                meta: { title: '能源流向图管理', icon: 'dashboard' },
            },
            {
                path: 'Index3',
                name: 'Screen_Index3',
                component: () => import('@/views/Screen/Index3'),
                meta: { title: '自定义报表管理', icon: 'dashboard' },
            },
            {
                path: 'Index4',
                name: 'Screen_Index4',
                component: () => import('@/views/Screen/Index4'),
                meta: { title: '能源平衡表管理', icon: 'dashboard' },
            },
            {
                path: 'Look1',
                name: 'Screen_Look1',
                component: () => import('@/views/Screen/Look1'),
                meta: { title: '配电图', icon: 'dashboard' },
            },
            {
                path: 'Look2',
                name: 'Screen_Look2',
                component: () => import('@/views/Screen/Look2'),
                meta: { title: '能源流向图', icon: 'dashboard' },
            },
            {
                path: 'Look3',
                name: 'Screen_Look3',
                component: () => import('@/views/Screen/Look3'),
                meta: { title: '自定义报表', icon: 'dashboard' },
            },
            {
                path: 'Look4',
                name: 'Screen_Look4',
                component: () => import('@/views/Screen/Look4'),
                meta: { title: '能源平衡表查询', icon: 'dashboard' },
            },
        ],
    },
    {
        path: '/AccountInfo',
        component: layout,
        meta: {
            requireAuth: true, // 配置是否需要登陆验证
        },
        children: [{
            path: 'Index',
            name: 'AccountInfo_Index',
            component: () => import('@/views/AccountInfo/Index'),
            meta: { title: '用户管理管理', icon: 'dashboard' },
        }],
    },

    {
        path: '/Design',
        name: 'Design',
        component: () => import('@/views/Home'),
    },
    {
        path: '/BigScreen',
        name: 'DesignView',
        component: () => import('@/views/BigScreen'),

    },
    {
        path: '/member',
        name: 'accountinfo',
        meta: {
            requireAuth: true, // 配置是否需要登陆验证
        },
        component: () => import('@/views/BigScreen'),
    },

    {
        path: '/web3d',
        component: layout,
        meta: {
            requireAuth: true, // 配置是否需要登陆验证
        },
        children: [{
            path: 'water',
            name: 'web3d_water',
            component: () => import('@/views/Web3d/Water'),
            meta: { title: '三维用水监测', icon: 'dashboard' },
        }],
    },


]

// 页面刷新时，重新赋值token
if (window.localStorage.getItem('sessionId')) {
    store.commit(actions.LOGIN, window.localStorage.getItem('sessionId'))
}

// const router =new Router({
//     // mode: 'history',
//     mode: 'hash',
//     base: process.env.BASE_URL,
//     routes,
// })

const createRouter = () => new Router({
    mode: 'hash', // require service support
    scrollBehavior: () => ({ y: 0 }),
    base: process.env.BASE_URL,
    routes,
})

const router = createRouter()

router.beforeEach((to, from, next) => {
    if (to.matched.some(r => r.meta.requireAuth)) {
        if (store.state.token) {
            next()
        } else {
            next({
                path: '/login',
                query: { redirect: to.fullPath },
            })
        }
    } else {
        next()
    }
})

export function resetRouter () {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router
