<template>
  <div>
    <div v-for="item in menuList">
    <el-submenu v-if="item.isFolder" :index="item.menuId+''" :key="item.menuId">
      <template slot="title">
        <i :class="item.icon"></i>
        <span>{{ item.name }}</span>
      </template>
      <div v-for="children in item.children">
        <Menu v-if="children.isFolder" :menuList="[children]" ></Menu>
        <el-menu-item v-else :index="getUrl(children)"><i :class="children.icon"></i>{{ children.name }}
        </el-menu-item>
      </div>
    </el-submenu>
    <el-menu-item v-else :key="item.menuId" :index="getUrl(item)"> <i :class="item.icon"></i>{{ item.name }}
    </el-menu-item>
  </div>
  </div>
</template>
 
<script>

export default {
  name: 'Menu',
  data() {
    return {}
  },
  props: {
    menuList: {
      type: Array,
      default: [],
    },
  },
  methods: {
    getUrl(item) {
      return item.url+'?menuId='+item.menuId
    },
    open(item) {
      const routeUrl = this.$router.resolve({
        path: item.url,
        query: { menuId: item.menuId },
      });
      if(item.mode==0){
        window.open(routeUrl.href,'_self');
      }else if(item.mode==1){
        window.open(routeUrl.href,'_blank');
      }
     
    }
  }
}
</script>

 