

var apiCode = {
    Success: 0,
    //[Description("需要登录")]
    NeedLogin: 401,
    //[Description("访问受限")]
    Forbidden: 403,
    Error: 500,
    ShowDialog: 202,

    //[Description("非法请求")]
    BadRequest: 201,

    //[Description("请求失败")]
    Fail: 400,

    //[Description("请求太频繁了，请稍后再试")]
    TooManyRequests: 429,
};

export   default apiCode
