<template>
  <el-dialog :class="['message-dialog-wrap', customClass, center ? 'center':'']"
             :visible.sync="dialogVisible"
             ref="dialog"
             :top="top"
             :width="width"
             :show-close="showClose"
             :close-on-click-modal="modelClick"
             :append-to-body="true"
             @close="closeHandle">
    <div v-if="showTitle"
         slot="title"
         class="message-dialog-title">
      <slot name="title">
        <icon v-if="tipIcon"
              class="tips"
              :style="tipsStyle"
              :type="tipIcon" /> {{title}}
      </slot>
    </div>
    <div class="message-dialog-content">
      <slot>
        <el-input v-if="type=== 'prompt'"
                  v-model.trim="val"
                  size="small"
                  clearable
                  :placeholder="placeholder"></el-input>

        <div class="content"
             v-else>
          <p v-html="content"></p>
          <p class="subcontent"
             v-html="subContent"></p>
        </div>
      </slot>
    </div>
    <div v-if="showFooter"
         slot="footer"
         class="message-dialog-footer">

      <slot name="footer">
        <el-button class="cancel-btn"
                   v-if="showCancel"
                   size="small"
                   @click.native="handleAction(cancelAction)"
                   @keydown.enter="handleAction(cancelAction)">
          {{ cancelText }}
        </el-button>
        <el-button ref="confirm"
                   class="confirm-btn"
                   v-show="showConfirm"
                   size="small"
                   type="primary"
                   @click.native="handleAction(confirmAction)"
                   @keydown.enter="handleAction(confirmAction)">
          {{ confirmText }}
        </el-button>
      </slot>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'messagebox',
  components: {

  },
  props: {
    customClass: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'confirm' // prompt
    },
    title: {
      type: String,
      default: '标题'
    },
    top: {
      type: String,
      default: '10vh'
    },
    width: {
      type: String,
      default: '432px'
    },
    center: {
      type: Boolean,
      default: false
    },
    modelClick: {
      type: Boolean,
      default: true
    },
    showClose: {
      type: Boolean,
      default: true
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    showConfirm: {
      type: Boolean,
      default: true
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    cancelText: {
      type: String,
      default: '取消'
    },
    confirmText: {
      type: String,
      default: '确定'
    },
    cancelAction: {
      type: String,
      default: 'cancel'
    },
    confirmAction: {
      type: String,
      default: 'confirm'
    },
    tipIcon: {
      type: String,
      default: 'warn'
    },
    tipsStyle: {
      type: Object,
      default: () => ({})
    },
    placeholder: {
      type: String,
      default: '请输入'
    },
    content: {
      type: String,
      default: '内容'
    },
    subContent: {
      type: String,
      default: ''
    },
    initVal: {
      type: String,
      default: ''
    },
    valRule: {
      type: String,
      default: '\\S'
    },
    ruleTips: {
      type: String,
      default: '请输入'
    }
  },
  data () {
    return {
      dialogVisible: false,
      val: '',
      callback: null,
      handleType: 'cancel'
    }
  },
  mounted () {
    this.val = this.initVal
  },
  methods: {
    closeHandle () {
      setTimeout(() => {
        if (this.type && typeof (this.callback) === 'function') this.callback(this.type, this.handleType, this.val)
      })
      let wrapper = this.$refs.dialog.$el
      wrapper && wrapper.parentNode.removeChild(wrapper)
      this.$emit('close')
      this.$emit('update:visible')
      this.$destroy(true)
    },
    /**
   * @param {Object} row
   * 更新与编辑
   */
    show () {
      this.dialogVisible = true
    },
    handleAction (action) {
      if (this.type === 'prompt' && action === 'confirm') {
        let reg = new RegExp(this.valRule)
        if (!reg.test(this.val)) {
          this.$message.warning(this.ruleTips)
          return false
        }
      }
      this.handleType = action
      this.$emit(action, this.type, this.val)
      this.closeHandle()
    }
  }
}
</script>

<style scoped lang="postcss">
.message-dialog-wrap {
  line-height: 1;

  >>> {
    .message-dialog-content {
      line-height: 18px;
    }
    .message-dialog-title {
      display: flex;
      align-items: baseline;
    }
    .el-dialog__header {
      padding: 24px 24px 0;
      font-size: 14px;
      font-weight: 600;
      color: #303133;
      border-bottom: none;
    }
    .el-dialog__body {
      min-height: 90px;
      padding: 24px;
    }
    .el-dialog__footer {
      padding: 0 24px 24px;
      .el-button {
        min-width: 65px;
      }
    }
  }

  .tips {
    margin-right: 10px;
    color: $color-primary;
  }

  .content {
    font-size: 14px;
    font-weight: 400;
    color: #666666;

    p {
      display: inline;
    }
  }

  .subcontent {
    color: #999999;
  }

  &.center {
    >>> {
      .message-dialog-title {
        justify-content: center;
      }
      .content {
        text-align: center;
      }
      .el-dialog__footer {
        text-align: center;
      }
    }
  }
}
</style>
