<template>
  <div class="navbar" style="z-index: 9999;">
    <hamburger id="hamburger" :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />

    <breadcrumb class="breadcrumb-container" />

    <div class="right-menu">
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          <!-- <img :src="'https://www.baidu.com/img/PCtm_d9c8750bed0b3c7d089fa7d55720d6cf.png'" class="user-avatar">   -->
          <el-button>当前用户：{{ realName }}</el-button>
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <router-link to="/">
            <el-dropdown-item>
              首页
            </el-dropdown-item>
          </router-link>
          <!-- <a target="_blank" href="https://github.com/PanJiaChen/vue-admin-template/">
              <el-dropdown-item>Github</el-dropdown-item>
            </a>
            <a target="_blank" href="https://panjiachen.github.io/vue-element-admin-site/#/">
              <el-dropdown-item>Docs</el-dropdown-item>
            </a> -->
          <el-dropdown-item divided @click.native="openForm">
            <span style="display:block;">修改密码</span>
          </el-dropdown-item>
          <el-dropdown-item divided @click.native="logout">
            <span style="display:block;">退出登陆</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog :close-on-click-modal="false" :title="form.title" :visible.sync="form.dialogVisible"
      :width="form.dialogwidth" :modal-append-to-body="false" >
      <el-form :model="form.instance" :rules="form.rules" :ref="form.refForm" label-width="100px">
        <el-form-item label="原密码" prop="oldPassword">
          <el-input type="password" v-model="form.instance.oldPassword" auto-complete="new-password"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input type="password" v-model="form.instance.newPassword" auto-complete="new-password"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="checkNewPassword">
          <el-input type="password" v-model="form.instance.checkNewPassword" auto-complete="new-password"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="form.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updatePwd">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
  
<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/eems-component/Main/Breadcrumb/Index.vue'
import Hamburger from '@/eems-component/Main/Hamburger/Index.vue'
import { actions } from '@/store/account'
import accountinfoapi from '@/api/accountinfoapi'
import toast from '@/utils/toastv2'
import apiCode from '@/api/apiCode'


const defaultModel = {
  oldPassword: null,
  newPassword: null,
  checkNewPassword: null,
}

export default {
  data() {
    var validatenewPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'))
      } else {
        if (this.form.instance.checkNewPassword !== '') {
          this.$refs[this.form.refForm].validateField('checkNewPassword');
        }
        callback()
      }
    }
    var validatecheckNewPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入新密码'))
      } else if (value !== this.form.instance.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    }
    return {
      realName: '',
      form: {
        dialogVisible: false,
        title: null,
        dialogwidth: '0px',
        dialogheight: '0px',
        refForm: 'form',
        instance: Object.assign({}, defaultModel),
        rules: {
          oldPassword: [
            { required: true, message: '请输入原密码', trigger: 'blur' },
          ],
          newPassword: [
            { required: true,validator: validatenewPassword, trigger: 'blur' }
          ],
          checkNewPassword: [
            { required: true,validator: validatecheckNewPassword, trigger: 'blur' }
          ],
        },
      }
    }
  },
  components: {
    Breadcrumb,
    Hamburger
  },
  computed: {
    ...mapGetters([
      'sidebar',
    ])
  },
  mounted() {
    this.realName = localStorage.realName ?? ""
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      // await this.$store.dispatch('user/logout')

      var that = this;
      this.$axios.get(accountinfoapi.AccountInfo_Logout).then(function (res) {
        console.log(res);
      }).finally(async function () {
        await that.$store.commit(actions.LOGOUT)
      });
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    },
    openForm() {
      this.form.instance = Object.assign({}, defaultModel);
      this.form.title = "修改密码"
      this.form.dialogwidth = '400px'
      var that = this
      this.$nextTick(() => {
        let form = this.$refs[that.form.refForm]
        if (form !== undefined) {
          form.clearValidate()
        }
      })
      this.form.dialogVisible = true
    },
    updatePwd() {
      var that = this;
      this.$refs[this.form.refForm].validate((valid) => {
        if (valid) {
          that.$axios.post(accountinfoapi.UpdatePassword, that.form.instance)
            .then(response => {
              let res = response.data;
              if (res.code == apiCode.Success) {
                toast.success()
                that.form.dialogVisible=false
              } else {
                toast.error(res.msg)
              }

            })
        } else {
          return false;
        }
      });


    }


  }
}
</script>
  
<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, .08);
  z-index: 1;

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
  