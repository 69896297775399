import Vue from 'vue'
import message from './index.vue'

let MessageBoxConstructor = Vue.extend(message)
let Instance = null

const messageBox = function (options = {}) {
  Instance = new MessageBoxConstructor({
    propsData: options
  })

  Instance.show()
  document.body.appendChild(Instance.$mount().$el)

  return new Promise((resolve, reject) => { // eslint-disable-line
    Instance.callback = (type, action, val) => {
      if (type) {
        if (action === 'cancel') {
          reject(new Error('cancel'))
        } else {
          resolve({ type: type, action: action, val: val })
        }
      } else {
        reject(new Error('异常啦'))
      }
    }
  })
}

export default messageBox
