
<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">

    
      <!-- <router-view :key="key" /> -->
       <keep-alive :include="cachedViews">
        <router-view :key="key" />
      </keep-alive>
    </transition>
  </section>
</template>
  
<script>
export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.path
    },
     cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
  }
}
</script>
  
<style scoped>
.app-main {
  min-height: calc(100vh - 50px);
  width: 100%;
  position: relative;
  overflow: hidden;
  height: calc(100vh);
  overflow: auto;
  padding: 10px;
}
.fixed-header+.app-main {
  padding-top: 90px;
}

</style>
  
<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
  