import Vue from 'vue'
import moment from "moment"

export function deepCopy(target) {
    if (typeof target == 'object') {
        const result = Array.isArray(target) ? [] : {}
        for (const key in target) {
            if (typeof target[key] == 'object') {
                result[key] = deepCopy(target[key])
            } else {
                result[key] = target[key]
            }
        }

        return result
    }

    return target
}

export function swap(arr, i, j) {
    const temp = arr[i]
    Vue.set(arr, i, arr[j])
    Vue.set(arr, j, temp)
}

export function $(selector) {
    return document.querySelector(selector)
}

const components = ['VText', 'RectShape', 'CircleShape']
export function isPreventDrop(component) {
    return !components.includes(component) && !component.startsWith('SVG')
}

/** https://stackoverflow.com/questions/13719593/how-to-set-object-property-of-object-property-of-given-its-string-name-in-ja
 * 修改指定路径json的值
 * @param {object} obj  
 * @param {string} prop  "foo.bar.foobar" json路径
 * @param {object} value 需要修改的数据 
 */
export function assign(obj, prop, value) {
    if (typeof prop === "string")
        prop = prop.split(".");

    if (prop.length > 1) {
        var e = prop.shift();
        assign(obj[e] =
                 Object.prototype.toString.call(obj[e]) === "[object Object]"
                 ? obj[e]
                 : {},
               prop,
               value);
    } else
        obj[prop[0]] = value;
}

/** https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arrays-by-string-path
 * 获取指定路径json的值  Object.byString(someObj, 'part3[0].name');
 * @param {object} o   源对象
 * @param {string} s  "foo.bar.foobar" json路径
 * @returns {object} value 
 */
 export function  accessingByPath(o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}

export function isEmpty(obj) {
    if (typeof obj == "undefined" || obj == null|| JSON.stringify(obj) === '{}' || obj === "") {
        return true;
    } else {
        return false;
    }
}
  /**
 * 将数字取整为10的倍数
 * @param {Number} num 需要取整的值
 * @param {Boolean} ceil 是否向上取整
 * @param {Number} prec 需要用0占位的数量
 */
export function formatInt(num, prec = 2, ceil = true){
    const len = String(num).length;
    if (len <= prec) { return num };
    const mult = Math.pow(10, prec);
    return ceil ?
        Math.ceil(num / mult) * mult :
        Math.floor(num / mult) * mult;
} 
  /**
 * 根据开始时间结束时间获取X坐标
 * @param {startDate}  开始时间
 * @param {endDate}  结束时间
 * @param {type}  1日 2月
 */
 export function getxAxis(startDate,endDate,type=1){
    let xAxis=[]
    switch(type){
        case 1:
            for (var date = startDate; date <= endDate; date=moment(date).add(1, 'days')) {
                xAxis.push(moment(date).format('YYYY-MM-DD'))
            }
            break;
        case 2:
            for (var date = startDate; date <= endDate; date=moment(date).add(1, 'months')) {
                xAxis.push(moment(date).format('YYYY-MM'))
            }
            break;   
    }
    return xAxis
} 
export function  getUrlParam(stringurl) {
    const url = stringurl.split('?')[1];
    const param = {}
    for (const [name, value] of new URLSearchParams(url)) {
        param[name] = value
    }
    return param;
}

export function  getCurrUrlParam() {
    getUrlParam(document.location.href.replace('#/', ''));
}