import store from './index'
import toast from '@/utils/toast'
import generateID from '@/utils/generateID'
import { deepCopy } from '@/utils/utils'

export default {
    state: {
        copyData: [], // 复制粘贴剪切
        isCut: false,
    },
    mutations: {
        copy(state) {
            if (state.areaData.components.length == 0 && !state.curComponent) {
                toast('请选择组件')
                return
            }
            copyData(state)

            state.isCut = false
        },

        paste(state, isMouse) {
            if (state.copyData.length == 0) {
                toast('请选择组件')
                return
            }
            state.copyData.forEach((copy) => {
                const data = copy.data
                if (isMouse) {
                    data.style.top = state.menuTop
                    data.style.left = state.menuLeft
                } else {
                    data.style.top += 10
                    data.style.left += 10
                }
                creatid(data)
                store.commit('addComponent', { component: deepCopy(data) })
            });


            if (state.isCut) {
                state.copyData = []
            }
        },

        cut(state) {
            if (!state.curComponent) {
                toast('请选择组件')
                return
            }
            copyData(state)
            store.commit('deleteComponent')
            state.isCut = true
        },
    },
}

function copyData(state) {
    state.copyData = []
    if (state.areaData.components.length > 0) {
        state.areaData.components.forEach((component) => {
            state.copyData.push({
                data: deepCopy(component),
                index: component.index,
            });
        });
    } else {
        if (state.curComponent) {
            state.copyData = [{
                data: deepCopy(state.curComponent),
                index: state.curComponentIndex,
            }]
        }
    }
}
function creatid(data) {
    data.id = generateID()
    if (typeof data.propValue== 'object'&&data.component=="Group") {
        data.propValue.forEach((component) => {
            creatid(component);
        });
    }
}

