import Vue from 'vue'
import {componentclassCount,default as componentList} from './component-list'

const components = [
    'CircleShape',
    'Picture',
    'VText',
    'VButton',
    'Group',
    'RectShape',
    'LineShape',
    'VTable',
    'KonvaCircle',    
    'LoadSvgTest',
    'LoadSvgPath',
    'LoadGifTest',
    
]

components.forEach(key => {
    Vue.component(key, () => import(`@/custom-component/${key}/Component`))
    Vue.component(key + 'Attr', () => import(`@/custom-component/${key}/Attr`))
})

const svgs = [
    'SVGStar',
    'SVGTriangle',
]

svgs.forEach(key => {
    Vue.component(key, () => import(`@/custom-component/svgs/${key}/Component`))
    Vue.component(key + 'Attr', () => import(`@/custom-component/svgs/${key}/Attr`))
})



// //分类下面组件注册
for (let i = 2; i < componentclassCount.endId; i++) {
componentList.filter(f=>f.componentclassid===i ).forEach((item)=>{

    Vue.component(item.component, () => import(`@/custom-component/componentclassid${i}/${item.component}/Component`))
    Vue.component(item.component + 'Attr', () => import(`@/custom-component/componentclassid${i}/${item.component}/Attr`))

});
 
}

// var path='componentclassid2';

// const componentclassid2 = [
//     'JieDiDianDeng',
//     // 'daidianzujiedi',
//     // 'xianquanx3',
//     // 'jiedidianzusi',
//     // 'bianyaqi',
//      'dianzu',
//     // 'sanraozubianyaqi',
// ]
//  path='componentclassid2';
// componentclassid2.forEach(key => {
//     Vue.component(key, () => import(`@/custom-component/${path}/${key}/Component`))
//     Vue.component(key + 'Attr', () => import(`@/custom-component/${path}/${key}/Attr`))
// })







