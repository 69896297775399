let baseUrl = process.env.VUE_APP_API_SERVER_URL 

let apiUrl = { 
    GetMenuTree: '/api/v1/menu/tree', 
    GetMenuByMenuId: '/api/v1/menu/model', 
    SaveMenuInfo: '/api/v1/menu/save', 
    MenuInfoDelete: '/api/v1/Menu/Delete', 
    GetControllerAction: '/api/v1/controllerAction',
    GetControllerActionById: '/api/v1/controllerAction/model',
    SaveControllerAction: '/api/v1/controllerAction/save',
    ControllerActionDelete: '/api/v1/ControllerAction/Delete',
    GetMenuControllerAction: '/api/v1/menu/controlleraction',
    GetRoleControllerAction: '/api/v1/role/controlleraction',
    GetMenuAuthList: '/api/v1/menu/auth',
}

for (let key in apiUrl) {
    apiUrl[key] = baseUrl + apiUrl[key]
}
 
export default apiUrl