/**
 * Created by superman on 17/2/16.
 * http配置
 */

import axios from 'axios'
import router from '../router/index'
import store from '../store/index'
import  {actions} from '../store/account'

import apiCode from '@/api/apiCode' 
import messageBox from '@/eems-component/Login/messageBox/index'
import toastv2 from './toastv2'

// axios 配置
axios.defaults.timeout = 10000
axios.defaults.baseURL = process.env.VUE_APP_API_SERVER_URL  // 'https://api.github.com'

// http request 拦截器
axios.interceptors.request.use(
  config => {
    if (store.state.token) {
     // config.headers.Authorization = `token ${store.state.token}`
     config.headers['sessionId'] = `${store.state.token}`;
     config.headers['stationId'] = `${localStorage.stationId}`;
    }
    return config
  },
  err => {
    return Promise.reject(err)
  },
)

let isExistExpired = false
// http response 拦截器
axios.interceptors.response.use(
    (response) => {
        if (response.data?.code == apiCode.NeedLogin) {
          store.commit(actions.LOGOUT);
 
        if (!isExistExpired) {
            isExistExpired = true
        console.log(response);
        messageBox({
            type: 'confirm',
            confirmText: '确定',
            showCancel: false,
            title: '登陆超时',
            content: '需要您重新登陆'
          }).then(res => {
            if (res.action === 'confirm') {
              location.href = '/';
            }
          }).catch(() => {

          }).finally(() => {
            isExistExpired = false
          })
        }




            return;
        }

        return response;
    },
    (error) => {
      

      if(error?.code=='ERR_NETWORK')
      {
        //console.log(error.config.url)
        toastv2.error("接口地址调用失败")
      }

        if (error.response) {
            switch (error.response.status) {
                case 401:
                    // 401 清除token信息并跳转到登录页面
                    store.commit(actions.LOGOUT);

                    // 只有在当前路由不是登录页面才跳转
                    router.currentRoute.path !== "login" &&
                        router.replace({
                            path: "login",
                            query: { redirect: router.currentRoute.path },
                        });
            }
        }
        // console.log(JSON.stringify(error));//console : Error: Request failed with status code 402
        return Promise.reject(error.response?.data);
    }
);

export default axios
