<template>
     <div id="app">
    <router-view />
   <MiniProfiler v-if="debug" ></MiniProfiler>
  </div>
</template>



<script>
import MiniProfiler from './eems-component/Common/MiniProfiler.vue';
const isProd = process.env.NODE_ENV === 'production'
var  isMiniProfiler= window.appConfig.MiniProfiler
export default {
  components: { MiniProfiler },
  data() {
    return {
     debug:isMiniProfiler

    };
  },
}
</script>

<style>
#app {
    font-family:
        "Helvetica Neue",
        Helvetica,
        Arial,
        "PingFang SC",
        "Hiragino Sans GB",
        "Heiti SC",
        "Microsoft YaHei",
        "WenQuanYi Micro Hei",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* text-align: center; */
    color: #2c3e50;
}
</style>
