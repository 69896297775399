
import Konva from 'konva'
import {binditem,repeat} from '../component-list'

const componentclassid=3
 

const list = [
    {
        component: 'DataViewChartColumn',
        componentclassid:3,
        label: '柱状图',
        request: {         
           // binditem:{...binditem},
            repeat: {...repeat}, 
            chart:{
                period:'day', //　逐日，逐月 month
                binditem:[{...binditem,
                    color:Konva.Util.getRandomColor(),
                    barType: 'bar'
                }],
                barType: 'bar',


            }

        },
        propValue: {
             value:[],
             attribute:{
                chartTitle: '标题一', titleLeft: 'center', titleTop: 0, titleTextColor: '#ccc',
                xAxisShow: true, yAxisShow: true, xLineShow: true, yLineShow: true,
                xLabelColor: '#ccc', xLineColor: '#ccc', yLabelColor: '#0bd124', yLineColor: '#ccc',
                yGridLineShow: false, yTickShow: true, xTickShow:true,
                xAxisName:'', yAxisName:'',yAxisNameTextStyleColor :'#cccccc',yAxisNameTextStyleColor :'#cccccc',
                barBgShow: false, barBorderRadius: 5,barLabelShow: true,
                barLabelColor: '#ff0',barLabelSize: 10,
                gradualColor: true, barColor:'#409eff', barColor1: '#e9a90a',barColor2: '#0bcfcf',barColor3: '#cc0ce6',
                barWidth: 18, barType: 'bar',xFontRotate:0,
                barPath: 'path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z',
                legendTextColor:'#ddd',legendX:'center', legendY:'bottom',      legendShow:true,legendFontSize:12,orient: 'horizontal',
            },
 
        },
        icon: 'zhuzhuangtu',
        style: {
            width: 200,
            height: 200,
           // backgroundColor: 'rgba(255, 255, 255, 1)',
        },
    }, 
    
    {
        component: 'DataViewChartLine',
        componentclassid:componentclassid,
        label: '折线图',
        request: {         
           // binditem:{...binditem},
            repeat: {...repeat}, 
            chart:{
                period:'day', //　逐日，逐月 month
                binditem:[{...binditem,
                    color:Konva.Util.getRandomColor(),
                    barType: 'line'
                }],
                barType: 'line',


            }

        },
        propValue: {
             value:[],
             attribute:{
                chartTitle: '标题一', titleLeft: 'center', titleTop: 0, titleTextColor: '#ccc',
                subtext:'',subtextColor: '#aaa',
                xAxisShow: true, yAxisShow: true, xLineShow: true, yLineShow: true,
                xLabelColor: '#ccc', xLineColor: '#ccc', yLabelColor: '#0bd124', yLineColor: '#ccc',
                yGridLineShow: false, yTickShow: true, xTickShow:true,
                xAxisName:'', yAxisName:'',yAxisNameTextStyleColor :'#cccccc',yAxisNameTextStyleColor :'#cccccc',
                barBgShow: false, barBorderRadius: 5,barLabelShow: true,
                barLabelColor: '#ff0',barLabelSize: 10,
                gradualColor: true, barColor:'#409eff', barColor1: '#e9a90a',barColor2: '#0bcfcf',barColor3: '#cc0ce6',
                barWidth: 18, barType: 'line',xFontRotate:0,
                barPath: 'path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z',
                legendTextColor:'#ddd',legendX:'center', legendY:'bottom',      legendShow:true,legendFontSize:12,orient: 'horizontal',
            },
 
        },
        icon: 'zhexiantu',
        style: {
            width: 200,
            height: 200,
           // backgroundColor: 'rgba(255, 255, 255, 1)',
        },
    },  
    
    {
        component: 'DataViewScrollTable',
        componentclassid:componentclassid,
        label: '滚动表格',
        request: {         
          
            repeat: {...repeat}, 
            table:{
                period:'day', //　逐日，逐月 month
                binditem:[{...binditem}],     
            }

        },
        propValue: {
             value:[],
             attribute:{
           
                rowNum: 4,//显示行数
                headerBGC:'#00BAFF',//表头背景色
                oddRowBGC:'#003B51',//奇数行背景色
                evenRowBGC:'#0A2732',//偶数行背景色
                waitTime: 2000,//轮播时间间隔(ms)
                headerHeight: 35,//表头高度
              //  rowHeight: 25,//表行高度
                indexHeader: '序号',//行号表头
                carousel:'single',//轮播方式'single'|'page'
                hoverPause:true,//悬浮暂停轮播
                index: true,//开启序号
                indexColor:'#00BAFF',//行号背景
        
            }
 
        },
        icon: 'gundongbiaoge',
        style: {
            width: 200,
            height: 200,
            color: '#fff',
           // backgroundColor: 'rgba(255, 255, 255, 1)',
        },
    }, 

    {
        component: 'DataViewChartGauge',
        componentclassid:componentclassid,
        label: '仪表盘',
        request: {         
            binditem:{...binditem},
            repeat: {...repeat},

        },
        propValue: {
             value:'23',
            attribute:{
                title:'速度',min:0,max:100,lineDistance:5,labelDistance:10,labelSize:12,tickLength:5,
                pointerLength:75,pointerWidth:6,detailSize:25,detailColor:'#ddd',titleSize:12,titleColor:'#ddd',
                lineWidth:3,color1:'#67e0e3',color2:'#37a2da',color3:'#fd666d',itemColor:'#6ADA13',
                startAngle:225,endAngle:-45
            }
 
        },
        icon: 'yibiaopan',
        style: {
            width: 200,
            height: 200,
           // backgroundColor: 'rgba(255, 255, 255, 1)',
        },
    }, 
    {
        component: 'DataViewBorder',
        componentclassid:componentclassid,
        label: '边框',
        // request: {         
        //     binditem:{...binditem},
        //     repeat: {...repeat},

        // },
        propValue: {
          
            attribute:{
                borderType:'dv-border-box-1',
                borderColor1:'#409eff',borderColor2:'#f00',backgroundColor:'rgba(0, 0, 0, 0)',
                borderTitle:'标题1',titleWidth:250,//样式11特殊配置
                dur:3, reverse:false//样式8特殊配置
            }
 
        },
        icon: 'biankuang',
        style: {
            width: 200,
            height: 200,
           // backgroundColor: 'rgba(255, 255, 255, 1)',
        },
    }, 

    {
        component: 'DataViewDecoration',
        componentclassid:componentclassid,
        label: '炫酷装饰',
        // request: {         
        //     binditem:{...binditem},
        //     repeat: {...repeat},

        // },
        propValue: {
          
            attribute:{
                decorationType:'dv-decoration-1', color1:'#409eff',color2:'#f00',text:'',textColor:'#ff0'
            }
 
        },
        icon: 'zhuangshi',
        style: {
            width: 200,
            height: 200,
           // backgroundColor: 'rgba(255, 255, 255, 1)',
        },
    }, 
    
    
    {
        component: 'DataViewrRectNum',
        componentclassid:componentclassid,
        label: '数值框',
        request: {         
            binditem:{...binditem},
            repeat: {...repeat},

        },
        propValue: {
          
            value:'6688',
            attribute:{
                fontSize:36,padding:10,borderColor:'#22628D',bgColor: 'rgba(5,30,85,0.68)',color:'#7ca1d3'
            }
 
        },
        icon: 'shuzhi',
        style: {
            width: 220,
            height: 60,
           // backgroundColor: 'rgba(255, 255, 255, 1)',
        },
    }, 

    {
        component: 'DataViewCarousel',
        componentclassid:componentclassid,
        label: '轮播图', 
        propValue: {
          
            attribute:{
                trigger:'hover', fit:'cover', imgUrls:[],
            }
 
        },
        icon: 'lunbo',
        style: {
            width: 200,
            height: 200,
           // backgroundColor: 'rgba(255, 255, 255, 1)',
        },
    },  
    
    {
        component: 'DataViewWaterLevel',
        componentclassid:componentclassid,
        label: '水位图', 
        request: {         
            binditem:{...binditem},
            repeat: {...repeat},

        },
        propValue: {
            attribute:{
                shape: 'rect',//水位图形状rect/roundRect/round
                colors: ['#1b6782'],//水位图配色
                waveNum: 3,//波浪数量
                waveHeight: 40,//波浪高度
                waveOpacity: 0.4,//波浪透明度
                formatter: '{value}%',//信息格式化
                data:[30]
            }
 
        },
        icon: 'shuiwei',
        style: {
            width: 200,
            height: 200,
           // backgroundColor: 'rgba(255, 255, 255, 1)',
        },
    }, 
]

export default list