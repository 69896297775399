import Vue from 'vue'
import ElementUI from 'element-ui'
import App from './App'
import router from './router'
import store from './store'
import '@/custom-component' // 注册自定义组件

import VueKonva from 'vue-konva';
import * as echarts from 'echarts';


import '@/assets/iconfont/iconfont.css'
import '@/styles/animate.scss'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/reset.css'
import '@/styles/global.scss'
import '@/styles/index.scss' // global css
import dataV from '@jiaminghi/data-view'
import '@/icons'
 

import axios from './utils/http'

Vue.prototype.$axios = axios;
Vue.prototype.$echarts = echarts;
Vue.use(VueKonva);
Vue.use(dataV);

//设置按钮权限至vuex
// store.dispatch('buttonpermission/generateRoutes')


Vue.use(ElementUI, { size: 'Medium' })
Vue.config.productionTip = false

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
})
