<template>

</template>


<script>


import axios from '@/utils/http';
var baseUrl=process.env.VUE_APP_API_SERVER_URL 
export default {
 
    name: 'miniprofiler',
    data() {
      return {

        id:'mini-profiler',
        dataVersion:'',
        dataPosition:'right',
        dataChildren:true,
        dataMaxTraces:35,
      dataAuthorized:true,
     // dataAuthorized:false,
        dataStartHidden:false,
        dataToggleShortcut:'Alt+P',
        dataTrivialMilliseconds:35,
        dataTrivial:true,
        dataControls:true,
        dataCurrentId:'',
        dataIds:'',
        scriptAsync:true,
        innerHTML:'',
        dataPath:baseUrl+'/mini-profiler-resources/',
      scriptSrc:baseUrl+'/mini-profiler-resources/includes.min.js',     
      cssSrc:baseUrl+'/mini-profiler-resources/includes.min.css',
 
      };
    },
    props: {

       
    }, 
    mounted(){
        this.axiosSetUp();
        this.appendDivElement();
        this.appendCssLink();
    },
    computed: {

    },
    mounted(){
           this.axiosSetUp()
        this.appendDivElement();
        this.appendCssLink();
    },
    methods: {
          axiosSetUp(){
        const key = 'MiniProfiler';
      
        axios.interceptors.response.use(function success(config) {

            if(!config.headers['x-miniprofiler-ids'])
            {
                console.log('未找到 x-miniprofiler-ids')
                return config;
            }

            const miniProfiler = window[key];
        if(miniProfiler){
            const miniProfilerIds = JSON.parse(config.headers['x-miniprofiler-ids']);
            miniProfiler.fetchResults(miniProfilerIds);
        }

            return config;
        }, function bug(error) {
            return Promise.reject(error);
        })

    
        },
        appendDivElement() {
        const body = document.body;
        const script = document.createElement('script');
        script.innerHTML = this.innerHTML;
        script.src =this.scriptSrc;//this.scriptSrc+"?"+(+new Date());
        script.setAttribute('data-version', this.dataVersion);
        script.setAttribute('data-path', this.dataPath);
        script.setAttribute('data-position', this.dataPosition);
        script.setAttribute('id', this.id);
        script.setAttribute('data-current-id', this.dataCurrentId);
        script.setAttribute('data-ids', this.dataIds);
        script.setAttribute('data-trivial', this.dataTrivial.toString());
        script.setAttribute('data-children', this.dataChildren.toString());
        script.setAttribute('data-max-traces', this.dataMaxTraces.toString());
        script.setAttribute('data-controls', this.dataControls.toString());
        script.setAttribute('data-authorized', this.dataAuthorized.toString());
        script.setAttribute('data-start-hidden', this.dataStartHidden.toString());
        script.setAttribute('data-toggle-shortcut', this.dataToggleShortcut);
        script.setAttribute('data-trivial-milliseconds', this.dataTrivialMilliseconds.toString());
        script.setAttribute('data-color-scheme', 'Light');
        script.setAttribute('data-scheme', 'Light');
        script.async = this.scriptAsync;
        body.appendChild(script);
    },
    appendCssLink() {
        const body = document.body;
        const css = document.createElement('link');
        css.href = this.cssSrc;
        css.rel = 'stylesheet';
        body.appendChild(css);
    }

      
    },
  };
  </script>