/**
 * Created by superman on 17/2/16.
 * vuex types
 */

import { resetRouter } from "@/router";

export const actions = {
    LOGIN :'login',
    LOGOUT :'logout',

} 

 

 

export default {
    state: {
        user: {},
        token: null,
    },
    mutations: {
      
        [actions.LOGIN]: (state, data) => {
            localStorage.sessionId = data;
            state.token = data;
        },
        [actions.LOGOUT]: (state) => {
            localStorage.removeItem('sessionId');
            state.token = null
            resetRouter();
        },
        
    },
}