//按钮权限状态缓存
 
/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
export function hasPermission(iewcode, buttoncode) {
  
    return true;//根据 state.addRoutes判断
}
 

const state = { 
  addButtons: []
}

const mutations = {
  Add_Button_Permission: (state,  buttons) => {
    state.addRoutes = buttons
   
  }
}

const actions = {
  generateRoutes({ commit }) {


    return new Promise(resolve => {

    
      let accessedRoutes=[];
   
      //获取接口按钮权限
      


      //设置vuex
      commit('Add_Button_Permission', accessedRoutes)
      resolve(accessedRoutes)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
