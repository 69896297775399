<template>
    <div :class="{ 'has-logo': showLogo }">
        <logo v-if="showLogo" :collapse="isCollapse" />
        <el-scrollbar wrap-class="scrollbar-wrapper">
            <el-menu ref="menu" :collapse="isCollapse" router :default-active="defaultActiveMenu" :default-openeds="defaultOpenedsMenu"
             @open="opensubmenu" @close="closesubmenu" @select="selectmenu"
                class="el-menu-vertical-demo" background-color="#1f2d3d" text-color="#fff" active-text-color="#ffd04b">
                <Menu :menuList="menuList" />
            </el-menu>
        </el-scrollbar>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import Menu from './Menu'
import variables from '@/styles/variables.scss'
import MenuInfoApi from '@/api/MenuInfoApi'
import toast from '@/utils/toastv2';
import apiCode from '@/api/apiCode';
import { isEmpty } from '@/utils/utils'

export default {
    components: { Menu, Logo },
    data() {
        return {
            menuList: [],
            defaultActiveMenu:'',
            defaultOpenedsMenu:[],
        }
    },
    created() {

    },
    mounted() {
        this.getmenuList()
    },
    methods: {
        selectmenu(index, indexPath) {
            localStorage.menuActiveindex=index
        },
        opensubmenu(index, indexPath) {
            let menuindex = localStorage.menuindex
            if (isEmpty(menuindex)) {
                localStorage.menuindex = index
            } else {
                var Arraymenu = menuindex.split(',')
                let i = Arraymenu.findIndex(s => s == index)
                if (i < 0) {
                    Arraymenu.push(index)
                    localStorage.menuindex = Arraymenu.join(',')
                }
            }
        },
        closesubmenu(index, indexPath) {
            let menuindex = localStorage.menuindex
            if (!isEmpty(menuindex)) {
                var Arraymenu = menuindex.split(',')
                let i = Arraymenu.findIndex(s => s == index)
                if (i >= 0) {
                    Arraymenu.splice(i, 1)
                    localStorage.menuindex = Arraymenu.join(',')
                }
            }
        },
        getmenuList() {
            this.$axios
                .get(MenuInfoApi.GetMenuAuthList)
                .then((response) => {
                    let res = response.data;
                    if (res.code == apiCode.Success) {
                        this.menuList = res.data
                        this.defaultOpenedsMenu=localStorage.menuindex.split(',')
                        this.defaultActiveMenu=localStorage.menuActiveindex
                    }
                })
        },
    },
    computed: {
        ...mapGetters([
            // 'permission_routes',
            'sidebar',
        ]),
        activeMenu() {
            const route = this.$route
            const { meta, path } = route
            // if set path, the sidebar will highlight the path you set
            if (meta.activeMenu) {
                return meta.activeMenu
            }
            return path
        },
        showLogo() {
            return this.$store.state.settings.sidebarLogo
        },
        variables() {
            return variables
        },
        isCollapse() {
            return !this.sidebar.opened
        },
    }
}
</script>
