
//http://localhost:51420/api/v1/AccountInfo/Login?userName=abc&password=d


var baseUrl = process.env.VUE_APP_API_SERVER_URL

var apiUrl = {
    AccountInfo_Login: '/api/v1/account/Login', //登陆
    AccountInfo_Logout: '/api/v1/account/Logout', //退出登陆
    GetAccountList: '/api/v1/account/list',
    GetAccountByAccountId: '/api/v1/account/model',
    AccountSave: '/api/v1/account/save',
    AccountDisabled: '/api/v1/account/Disabled',
    ResetPassword: '/api/v1/account/password/reset',
    UpdatePassword: '/api/v1/account/password/update',
}

for (let key in apiUrl) {
    apiUrl[key] = baseUrl + apiUrl[key]
}

export default apiUrl